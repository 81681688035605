import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Image, Input, Row } from 'antd'
import axios from 'axios';

import logo from '..//assets/logo.png';
import avatargirl from '../assets/avatargirl.png';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../Constant';

const DeleteAccount = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleDelete = async (value) => {
        setLoading(true)
        setError(null);
        try {
            const response = await axios.post(`${baseUrl}users/deleteUserByEmailAndPassword`, {
                email: value?.email,
                password: value?.password,
                reason: value?.message
            });
            if (response) {
                navigate('/', { replace: true })
            }
        } catch (error) {
            console.log("error", error?.response?.data?.message)
            setError(error?.response?.data?.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(null);
            }, 1500);
        }
    }, [error])

    return (
        <div style={{ minHeight: '80vh', display: 'grid', placeItems: 'center' }}>
            <section style={{ display: 'grid', placeItems: 'center' }} className='common_container' id="Contact">
                <Image src={logo} alt='logo' height={105} preview={false} draggable={false} />
                <Row gutter={[]} align={'middle'}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} className='contact_girl' style={{ marginTop: 25 }}>
                        <img style={{ width: '80%' }} src={avatargirl} alt='logo' />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} className='contact_form'>
                        <p className='title'>Request Delete Account</p>
                        <p className='section_paragraph'>Once you will delete your account, It can't be recovered</p>
                        <Form name="form" onFinish={handleDelete} layout="vertical" className="modal_form" >
                            <Form.Item name="email" rules={[{ required: true, message: "Please Enter Email", type: 'email' }]} >
                                <Input className='form_style' placeholder="E-mail*" />
                            </Form.Item>
                            <Form.Item name="password" rules={[{ required: true, message: "Please Enter Password", type: 'string' }]} >
                                <Input className='form_style' placeholder="********" />
                            </Form.Item>
                            <Form.Item name="message" rules={[{ required: true, message: "Please Enter Meassage" }]} >
                                <Input className='messageform_style' placeholder="Message*" />
                            </Form.Item>
                            <Form.Item >
                                <Button loading={loading} className="send_button" htmlType='submit'>Delete</Button>
                            </Form.Item>
                            {error && <div className='text-danger border text-center fs-3 '> <b>{error}</b></div>}
                        </Form>
                    </Col>

                </Row>
            </section>
        </div>
    )
}

export default DeleteAccount