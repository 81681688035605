import React, { useEffect, useRef, useState } from 'react'
import logo from '..//assets/logo.png';
import ios from '..//assets/ios.png'
import android from '..//assets/android.png'
import player from '../assets/about.png'
import mid1 from '../assets/mid1.png'
import mid2 from '../assets/mid2.png'
import mid3 from '../assets/mid3.png'
import house from '../assets/house.png'
import girl from '../assets/girl.png'
import boy from '../assets/boy.png'
import mobile from '../assets/mobile.png'
import avatargirl from '../assets/avatargirl.png'
import { MenuOutlined } from '@ant-design/icons';
import './Landing.css'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { Button, Carousel, Col, Drawer, Form, Image, Input, Row } from 'antd';
import { Link } from 'react-scroll';
import axios from 'axios';
import { baseUrl, fileUrl } from '../Constant';
import { toast } from 'react-toastify';

const Landing = () => {
    const slider = useRef(null);
    const [contactForm] = Form.useForm();

    const [open, setOpen] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [featureData, setFeatureData] = useState([]);
    const [downloadContent, setDownloadContent] = useState("");

    /* Features Content */
    const getFeatures = async (value) => {
        setLoading(true)
        setError(null);
        try {
            const response = await axios.get(`${baseUrl}admin/features`);
            if (response) {
                setFeatureData(response?.data?.data)
            }
        } catch (error) {
            console.log("error", error?.response?.data?.message)
            setError(error?.response?.data?.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    /* Download Content */
    const getDownload = async (value) => {
        setLoading(true)
        setError(null);
        try {
            const response = await axios.get(`${baseUrl}admin/landing_misc`);
            if (response) {
                setDownloadContent(response?.data)
            }
        } catch (error) {
            setError(error?.response?.data?.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    /* Contact Form */
    const onSubmitContactForm = async (value) => {
        console.log('Success:', value);
        toast.dismiss();
        setLoading(true)
        try {
            const response = await axios.post(`${baseUrl}supports/add`, {
                name: value?.name,
                email: value?.email,
                message: value?.message
            });
            if (response) {
                toast.success("Sent!")
                setLoading(false);
                contactForm.resetFields();
            }
        } catch (error) {
            setLoading(false)
            console.log("error", error?.response?.data?.message)
            setError(error?.response?.data?.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getDownload()
        getFeatures()
    }, [])

    return (
        <>
            {/* HEADER */}
            <div className='head_container'>
                <Row className='common_container header__div'>
                    <Col className='heder_space' md={24} lg={4} xs={24} sm={24}>
                        <img className='logo' src={logo} alt='logo' />
                        <div className='showMenu_btn' onClick={() => setOpen(true)} >
                            <MenuOutlined style={{ fontSize: '1.5rem' }} />
                        </div>
                    </Col>
                    <Col xs={24} sm={24}  md={24} lg={16} className={'heading_detail'} style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Link className="heading" activeStyle={{ color: '#FE6F27' }} to="Home" spy={true} smooth={true} delay={50} duration={50} offset={-70}>Home</Link>
                        <Link className="heading" activeStyle={{ color: '#FE6F27' }} to="About" spy={true} smooth={true} delay={50} duration={50} offset={-70}>About Us</Link>
                        <Link className="heading" activeStyle={{ color: '#FE6F27' }} to="Features" spy={true} smooth={true} delay={50} duration={50} offset={-80}>Features</Link>
                        <Link className="heading" activeStyle={{ color: '#FE6F27' }} to="Download" spy={true} smooth={true} delay={50} duration={50} offset={-70}>
                            Download
                        </Link>
                        <Link className="heading" activeStyle={{ color: '#FE6F27' }} to="Contact" spy={true} smooth={true} delay={50} duration={50} offset={-70}>
                            Contact Us
                        </Link>
                    </Col>
                    <Col md={24} lg={4} xs={24} sm={24} >
                        <div className='playestore_button d-flex gap-2 hideonMD'  >
                            <a href={downloadContent?.download_ios_link} target='_blank' ><img src={ios} alt='logo' /></a>
                            <a href={downloadContent?.download_android_link} target='_blank' ><img src={android} alt='logo' /></a>
                        </div>
                    </Col>
                </Row>
            </div>
        
            {/* SWAP IMAGE SECTION s  */}
            <section id="Home" className='homeSection'>
                <Carousel dots autoplay={true} autoplaySpeed={2000} effect="fade" >
                    <div className='background_img_container'>
                        <div className='background_img imgsectio1' />
                        <div className='swap_text mx-auto '>
                            {/* <h3 className='heaing_tag'>FIND THE <span style={{ color: "#FE6F27" }}>BEST</span> PLAYER</h3>
                            <h6 className='text_para'>The Fastest Way To Get Recruited</h6>
                            <Link to="Download" spy={true} smooth={true} delay={50} duration={50} offset={-70}>
                                <Button className='try_button' type="primary">Try for free</Button>
                            </Link> */}
                        </div>
                    </div>
                    <div className='background_img_1 imgsectio1'>
                        <div className='swap_text mx-auto'>                       
                        </div>
                    </div>
                    <div className='background_img_2 imgsectio1'>   </div>
                    <div className='background_img_3 imgsectio1'>   </div>
                    <div className='background_img_4 imgsectio1'>   </div>
                    <div className='background_img_5 imgsectio1'>   </div>
                </Carousel>
            </section>

            {/* SECTION ABOUT US */}
            <section id="About" className='aboutus_background aboutPadding'>
                <Row gutter={[]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <div className='leftside_about common_container'>
                            <h1 className='title'>About Us</h1>
                            <article className='' style={{ fontSize: 17, lineHeight: 2 }} dangerouslySetInnerHTML={{ __html: downloadContent?.about }} />

                            <Link to="Download" spy={true} smooth={true} delay={50} duration={50} offset={-70}>
                                <Button className='try_button' type="primary">Download Now</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <img className='rightside_player' src={player} alt='logo' style={{ height: '100%', width: '100%' }} />
                    </Col>
                </Row>
            </section>

            {/* second carousel*/}
            <section className='common_container'>
                <Row gutter={[]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div >
                            <Carousel dots={true} ref={slider} autoplay={true} autoplaySpeed={2000} effect="fade">
                                <div className="">
                                    <img className='twoplayer_img' src={mid1} alt='logo' />
                                </div>
                                <div className="">
                                    <img className='twoplayer_img' src={mid2} alt='logo' />
                                </div>
                                <div className="">
                                    <img className='twoplayer_img' src={mid3} alt='logo' />
                                </div>
                            </Carousel>
                            <div className='slidebutton' style={{ position: 'absolute', top: '50%', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <div className=''>
                                    <AiOutlineLeft className='react__icon' style={{ cursor: 'pointer' }} onClick={() => slider.current.prev()} />
                                </div>
                                <div className='' >
                                    <AiOutlineRight className='react__icon' style={{ cursor: 'pointer' }} onClick={() => slider.current.next()} />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </section>

            {/* Awesome Features */}
            <section className='common_container ' id="Features">
                <div className='features_section'>
                    <p className='title'>Awesome Features</p>
                    <p className='paragraph'>Take your recruiting to a new level! </p>
                </div>

                <Row gutter={[80, 20]} style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                    {featureData?.map((item, index) => {
                        let media = item?.media ? `${fileUrl}${item?.media}` : house
                        return (
                            <Col sm={24} md={24} lg={8} xl={8} key={index} >
                                <div className='awesome_MainDiv'>
                                    <img className='features_image' src={media} alt="image" />
                                    {/* <img className='features_image' src={media} alt="image" /> */}
                                    <div style={{ textAlign: 'center', width: '100%' }}>
                                        <h6 style={{ marginTop: 12, fontSize: '1.2rem', fontWeight: 600 }}>{item?.title}</h6>
                                        <p className='paragraph'>{item?.desc}</p>
                                    </div>
                                </div>
                            </Col>
                        )
                    })}
                </Row>

                <div style={{ textAlign: 'center' }}>
                    <Link to="Contact" spy={true} smooth={true} delay={50} duration={50} offset={-70}>
                        <Button className='contact_button' type="primary">Contact Us</Button>
                    </Link>
                </div>
            </section>

            {/* downdld section */}
            <section className='common_container' id="Download" style={{ display: 'grid', placeItems: 'center' }} >
                <div className='mobile_background'>
                    <Row gutter={[]}>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div>
                                <img className="mobile_image" src={mobile} alt='logo' />
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} className='dwnldContent_parent'>
                            <div className=''>
                                <p className='title'>{downloadContent?.download_title}</p>
                                <p className='section_paragraph' dangerouslySetInnerHTML={{ __html: downloadContent?.download_desc }} />
                                <div className='playestore_button d-flex gap-2' >
                                    <a href={downloadContent?.download_ios_link} target='_blank' ><img src={ios} alt='logo' /></a>
                                    <a href={downloadContent?.download_android_link} target='_blank' ><img src={android} alt='logo' /></a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>

            {/* contact */}
            <section style={{ display: 'grid', placeItems: 'center' }} className='common_container' id="Contact">
                <Row gutter={[]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} className='contact_girl' style={{ marginTop: 25 }}>
                        <img style={{ width: '80%' }} src={avatargirl} alt='logo' />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} className='contact_form'>
                        <p className='title'>Contact Us</p>
                        <p className='section_paragraph'>We are here to help! Please reach out below, and we will have one of our customer service representatives respond as soon as possible.</p>
                        <Form form={contactForm} name="form" onFinish={onSubmitContactForm} layout="vertical" className="modal_form" >
                            <Form.Item rules={[{ required: true, message: "Please Enter Name" }]} name="name" >
                                <Input className='form_style' placeholder="Name*" />
                            </Form.Item>
                            <Form.Item rules={[{ required: true, message: "Please Enter Email" }]} name="email" >
                                <Input className='form_style' placeholder="E-mail*" />
                            </Form.Item>
                            <Form.Item rules={[{ required: true, message: "Please Enter Meassage" }]} name="message" >
                                <Input className='messageform_style' placeholder="Message*" />
                            </Form.Item>
                            <Form.Item >
                                <Button loading={loading} className="send_button" htmlType='submit'>Send</Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </section>

            {/* footer */}
            <section >
                {/* <div className='footer_parent common_container'>
                    <div className='footer_detail'>
                        <Link activeStyle={{ color: '#FE6F27' }}
                            className='heading'
                            to="Home" spy={true} smooth={true} delay={100} duration={100}>Home</Link>
                        <Link to="About" spy={true} smooth={true} delay={50} duration={50} className='heading' activeStyle={{ color: '#FE6F27' }}>About Us</Link>
                        <Link className="heading" activeStyle={{ color: '#FE6F27' }} to="Features" spy={true} smooth={true} delay={50} duration={50} offset={-80}>Features</Link>
                        <Link className="heading" activeStyle={{ color: '#FE6F27' }} to="Download" spy={true} smooth={true} delay={50} duration={50} offset={-100}>
                            Download
                        </Link>
                        <Link className="heading" activeStyle={{ color: '#FE6F27' }} to="Contact" spy={true} smooth={true} delay={50} duration={50}>
                            Contact Us
                        </Link>
                    </div>
                </div> */}
                <div className='bottom'>
                    <article > &copy; 2024-All Rights Reserved - SwipeZone Sports</article>
                </div>
            </section>

            {/*   for md screen */}
            <Drawer placement="right" onClose={() => setOpen(false)} open={open}>
                <Link className="heading" onClick={() => setOpen(!open)} activeStyle={{ color: '#FE6F27' }} to="Home" spy={true} smooth={true} delay={50} duration={50} offset={-70}>Home</Link>
                <Link className="heading" onClick={() => setOpen(!open)} activeStyle={{ color: '#FE6F27' }} to="About" spy={true} smooth={true} delay={50} duration={50} offset={-70}>About Us</Link>
                <Link className="heading" onClick={() => setOpen(!open)} activeStyle={{ color: '#FE6F27' }} to="Features" spy={true} smooth={true} delay={50} duration={50} offset={-70}>Features</Link>
                <Link className="heading" onClick={() => setOpen(!open)} activeStyle={{ color: '#FE6F27' }} to="Download" spy={true} smooth={true} delay={50} duration={50} offset={200}>
                    Download
                </Link>
                <Link className="heading" onClick={() => setOpen(!open)} activeStyle={{ color: '#FE6F27' }} to="Contact" spy={true} smooth={true} delay={50} duration={50} offset={-70}>
                    Contact Us
                </Link>
                <div className='download_butt'>
                    <div className='my-2'>
                        <Image height={50} preview={false} src={ios} alt='logo' />
                    </div>
                    <div>
                        <Image height={50} preview={false} src={android} alt='logo' />
                    </div>
                </div>
            </Drawer>
        </>
    )
}

export default Landing