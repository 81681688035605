import { Route, Routes } from "react-router-dom";   
import Landing from '../Screen/Landing';
import DeleteAccount from '../Screen/DeleteAccount';
const AppRouter = () => {
    return (
        <>
 
            <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/delete_account" element={<DeleteAccount />} />
            </Routes>
        </>
    );
}
export default AppRouter;