import AppRouter from './Routes';
import { HashRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="">
      <HashRouter>
        <AppRouter />
      </HashRouter>
      <ToastContainer />
    </div>
  );
}

export default App;